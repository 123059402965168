import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { RolesService } from '../services';
import { RoleCondition } from '../../../shared/interfaces';

/**
 * Checks roles of authenticated user and prevents navigation.
 * If user does not have access to desired page, it redirects to an informational page.
 */
@Injectable()
export class RoleGuard {
  constructor(
    private readonly roleService: RolesService,
    private readonly router: Router
  ) {}

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const roleCondition = childRoute.routeConfig?.data?.['role'] as RoleCondition;

    if (roleCondition) {
      await this.roleService.getRolesPromise();
      const hasRole = this.roleService.applyRoleCondition(roleCondition);

      if (!hasRole) {
        /** Does not wait for navigation. It will happen after guards exits. */
        void this.router.navigate(['forbidden'], {
          queryParams: { route: state.url },
        });
      }

      return hasRole;
    }

    return true;
  }
}
